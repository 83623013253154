<template>
    <el-dialog width="600px" top="40px" title="Ladda upp fil" :visible.sync="visible" :before-close="closeModal" :close-on-click-modal="false" @closed="resetModalState">
        <el-upload
            class="flex flex-col align-center my-5 text-center"
            accept=".txt,.se,.si"
            action="#"
            :before-upload="uploadFile"
            :file-list="fileList"
            :limit="1"
            :show-file-list="false"
            v-loading="$waiting.is('uploading')"
        >
            <el-button type="primary">Välj fil</el-button>
            <div slot="tip" class="el-upload__tip">fil från banken</div>
        </el-upload>

        <p class="text-center">
            {{ responseMessage }}
        </p>
        <span slot="footer" class="dialog-footer">
            <el-button @click="closeModal" type="primary">Stäng</el-button>
        </span>
    </el-dialog>
</template>
<script>
import Api from "./lopande_import.api";

export default {
    props: {
        clientId: {
            type: String,
            default: null,
        },
        visible: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            fileList: [],
            responseMessage: "",
            responseType: "",
        };
    },

    methods: {
        async uploadFile(event) {
            this.$waiting.start("uploading");
            let formData = new FormData();
            formData.append("files", event);
            try {
                const responseData = await Api.uploadFile({ clientId: this.clientId, file: formData });
                this.$emit("uploaded", responseData);
                this.$notify.success({ title: "Utfört" });
                this.closeModal();
            } finally {
                this.$waiting.end("uploading");
            }
            return new Promise.reject();
        },

        closeModal() {
            this.$emit("close");
        },

        dataUploaded(response) {
            this.responseMessage = response.message;
            this.responseType = response.type;
            this.$emit("uploaded", response.data);
        },

        resetModalState() {
            this.responseMessage = "";
            this.responseType = "";
        },
    },
};
</script>
